<template>
  <div id="automation">
    <b-row
      v-if="!specialCategory(response.type) && response.type != 'LIN'"
      class="match-height mt-2"
    >
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showKeep"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-card>
            <b-card-header style="display: block;">
              <b-card-title>
                {{ $t('automationChap') }}
              </b-card-title>
              <h6 class="text-primary ">
                {{ $t('establised') }}
              </h6>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col>
                  <b-button
                    variant="primary"
                    @click="keepWatching()"
                  >
                    {{ $t('rel') }}
                  </b-button>
                </b-col>
                <b-col
                  v-if="buscadoKeep"
                  style="text-align:end"
                  class="mr-2"
                >
                  <button
                    class="btn btn-success "
                    @click="asgKeepWatching()"
                  >
                    {{ $t('aplicar') }}
                  </button>
                </b-col>
              </b-row>
              <b-row
                v-if="buscadoKeep"
                class="d-flex mr-1"
              >
                <b-col class="mb-2">
                  <div />
                </b-col>
              </b-row>
              <b-row
                v-if="buscadoKeep"
                class="d-flex mr-1"
              >
                <b-col class="ml-2 mb-2">
                  <b-table
                    id="chapters"
                    striped
                    responsive
                    :sticky-header="true"
                    :no-border-collapse="true"
                    :items="chapters"
                    :fields="columns"
                    class="mb-0"
                  >
                    <template #cell(Contenido)="data">
                      <b-row class="align-items-center">
                        <b-col md="4">
                          <b-img
                            :src="buildImageUrl(data.item.imageUrl)"
                            style=" margin-right: 2rem;
                            width: 6rem;"
                            @error="errorImg"
                          />
                        </b-col>
                        <b-col>
                          <b class="m-2">{{ data.item.name }}</b>
                        </b-col>
                      </b-row>
                    </template>
                    <template #cell(prev)="data">
                      <b-row class="align-items-center">
                        <b-col md="4">
                          <b-img
                            :src="buildImageUrl(data.item.prev.imageUrl)"
                            style=" margin-right: 2rem;
                            width: 6rem;"
                            @error="errorImg"
                          />
                        </b-col>
                        <b-col>
                          <b class="m-2">{{ data.item.prev.name }}</b>
                        </b-col>
                      </b-row>
                    </template>
                    <template #cell(next)="data">
                      <b-row class="align-items-center">
                        <b-col md="4">
                          <b-img
                            :src="buildImageUrl(data.item.next.imageUrl)"
                            style=" margin-right: 2rem;
                            width: 6rem;"
                            @error="errorImg"
                          />
                        </b-col>
                        <b-col>
                          <b class="m-2">{{ data.item.next.name }}</b>
                        </b-col>
                      </b-row>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="show"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-card>
            <b-card-header style="display: block;">
              <b-card-title>
                {{ $t('automData') }}
              </b-card-title>
              <h6
                v-if="!final"
                class="text-primary "
              >
                {{ $t('automDataCat') }}
              </h6>
              <h6
                v-else
                class="text-primary "
              >
                {{ $t('autCont') }}
              </h6>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col class="mb-2">
                  <b-button
                    class="mt-2 mr-2"
                    variant="primary"
                    @click="clickElement()"
                  >
                    {{ $t('analizar') }}
                  </b-button>

                  <b-button
                    v-if="!final"
                    class="mt-2"
                    variant="primary"
                    @click="clickCategory()"
                  >
                    {{ $t('subcat') }}
                  </b-button>
                </b-col>
                <b-col style="text-align: right;">
                  <b-badge
                    v-if="buscado"
                    variant="primary"
                    style="font-size: 14px;"
                    class="m-2"
                  >
                    {{ contents.length + external.length + ' ' + $t('contents.elements') }}
                  </b-badge>
                  <b-badge
                    v-if="buscadoCat"
                    variant="primary"
                    style="font-size: 14px;"
                    class="m-2"
                  >
                    {{ categories.length + ' ' + $t('contents.elements') }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                v-if="response != null ? response.mediaLocation != 'BUK' : false
                "
              >
                <b-col>
                  <b-alert
                    show
                    variant="warning"
                  >
                    <div class="alert-body">
                      <feather-icon
                        class="mr-25"
                        icon="AlertTriangleIcon"
                      />
                      <span>
                        {{ $t('originMedia') }}
                      </span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row v-if="buscado">
                <b-col>
                  <h4 class="ml-2">
                    {{ $t('mas') }}
                  </h4>

                  <b-list-group class="scroll-list  m-1">
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t("contents.bckgInternal") }}: </b>

                        <b-img
                          :src="buildImageUrl(backgroundUrl)"
                          style=" margin-right: 2rem;
                            width: 6rem;"
                          @error="errorImg"
                        />
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="background != null && background != ''
                            ? false
                            : true
                          "
                          variant="success"
                          @click="asgImg()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('epg.ageClasific') }}: </b>
                        <h5>
                          {{
                            clasification != null
                              ? clasification.initial +
                                "-" +
                                clasification.description
                              : $t('emptyField')
                          }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="clasification != null ? false : true"
                          variant="success"
                          @click="asgClas()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('epg.genre') }}: </b>
                        <h5>
                          {{ genre != null ? genre.name : $t('emptyField') }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="genre != null ? false : true"
                          variant="success"
                          @click="asgGen()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('code.type') }} : </b>
                        <h5>
                          {{
                            type != null ? $t(categoryTypes[type]) : $t('emptyField')
                          }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="type != null ? false : true"
                          variant="success"
                          @click="asgType()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('resByUser') }}: </b>
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('limitNmax')"
                        >
                          <b-form-input
                            v-model="maxViews"
                            type="number"
                            maxlength="30"
                          />
                        </b-form-group>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgMaxViews()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('category.shortDescription') }}: </b>
                        <h5>
                          {{
                            shortDescription != "" && shortDescription != null
                              ? shortDescription
                              : $t('emptyField')
                          }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="shortDescription != null && shortDescription != ''
                            ? false
                            : true
                          "
                          variant="success"
                          @click="asgShortDes()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center mt-2 ml-2"
                        style="overflow-wrap: anywhere; flex-direction: column;"
                      >
                        <b-row
                          class="d-flex align-items-center"
                          style="margin-right: auto;"
                        >
                          <b-col>
                            <b>{{ $t('statusCont') }}: </b>
                          </b-col>
                          <b-col>
                            <b-form-group
                              label=""
                              class="mb-2 mr-1"
                            >
                              <b-form-select
                                id="labelStatus"
                                v-model="labelStatus"
                                :label="$t('message.tableHeader.status')"
                                @change="estados()"
                              >
                                <b-form-select-option
                                  v-for="s in status"
                                  :key="s.value"
                                  :value="s.value"
                                >
                                  {{ s.text }}
                                </b-form-select-option>
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row
                          id="fechas"
                          style="display: none;"
                        >
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('contents.datePublication')"
                            >
                              <flat-pickr
                                v-model="publishingDate"
                                class="form-control"
                                :placeholder="$t('contents.datePublication')"
                                :config="{
                                  enableTime: true,
                                  enableSeconds: true,
                                  dateFormat: 'Y-m-d H:i:S',
                                  minDate: 'today'
                                }"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('contents.dateFinishing')"
                            >
                              <flat-pickr
                                v-model="finishingDate"
                                class="form-control"
                                :placeholder="$t('contents.dateFinishing')"
                                :config="{
                                  enableTime: true,
                                  enableSeconds: true,
                                  dateFormat: 'Y-m-d H:i:S',
                                  minDate: publishingDate
                                }"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          :disabled="labelStatus != null ? false : true"
                          variant="success"
                          @click="asgStatus()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>

                    <b-list-group-item>
                      <b-row>
                        <b-col>
                          <b-form-group
                            class="mb-2 mr-1"
                          >
                            <label class="d-flex">
                              {{ $t('tagsCont') }}
                              <feather-icon
                                v-b-tooltip.hover.top.v-primary="$t('addTags')
                                "
                                class="ml-1"
                                icon="InfoIcon"
                              />
                            </label>
                            <v-select
                              v-model="selectedTag"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              multiple
                              taggable
                              push-tags
                              :placeholder="$t('addTagsC')"
                              @input="modalTags()"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="contents.length + external.length > 0"
                          class="align-self-center m-2"
                          style="text-align: end;"
                        >
                          <b-button
                            variant="success"
                            :disabled="selectedTag==0"
                            @click="asgTags()"
                          >
                            {{ $t('Asignar') }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="showDownload"
                      class="d-flex justify-content-between "
                    >
                      <div
                        class="d-flex align-items-center mt-2 ml-2"
                        style="overflow-wrap: anywhere; flex-direction: column;"
                      >
                        <b-row class="d-flex ">
                          <b-col md="6">
                            <b>{{ $t('contDown') }}: </b>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="isDownload"
                              switch
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgDownload()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center mt-2 ml-2"
                        style="overflow-wrap: anywhere; flex-direction: column;"
                      >
                        <b-row class="d-flex ">
                          <b-col md="6">
                            <b>{{ $t('showContentsCategories') }}: </b>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="showContentsCategories"
                              switch
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgCheckContents()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="platformMode == 'FRM'"
                      class="d-flex justify-content-between "
                    >
                      <div
                        class="d-flex align-items-center mt-2 ml-2"
                        style="overflow-wrap: anywhere; flex-direction: column;"
                      >
                        <b-row class="d-flex ">
                          <b-col md="6">
                            <b>{{ $t('freemium') }}: </b>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="isFreemium"
                              switch
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgFreemium()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="showAds"
                      class="d-flex justify-content-between "
                    >
                      <div
                        class="d-flex align-items-center mt-2 ml-2"
                        style="overflow-wrap: anywhere; flex-direction: column;"
                      >
                        <b-row class="d-flex ">
                          <b-col md="6">
                            <b>{{ $t('contAd') }}: </b>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="isAds"
                              switch
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgAds()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <!-- hasSubtitle -->
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center mt-2 ml-2"
                        style="overflow-wrap: anywhere; flex-direction: column;"
                      >
                        <b-row class="d-flex ">
                          <b-col md="6">
                            <b>{{ $t('contSub') }}: </b>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="hasSubtitle"
                              switch
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgSub()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <!-- endsubtitle -->
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('firstCat') }}: </b>
                        <h5>
                          {{ maincategory }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgMainCategory()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('season') }}: </b>
                        <h5>
                          {{ seasonCategory }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgSeasonCategory()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between ">
                      <div
                        class="d-flex align-items-center"
                        style="overflow-wrap: anywhere;"
                      >
                        <b class="m-2">{{ $t('qualityCont') }}: </b>
                        <h5>
                          {{ qualityOptions[quality] }}
                        </h5>
                      </div>
                      <div
                        v-if="contents.length + external.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgQuality()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
              <b-row v-if="buscadoCat">
                <b-col>
                  <h4 class="ml-2">
                    {{ $t('masiveChange') }}
                  </h4>
                  <b-list-group class="scroll-list m-1">
                    <b-list-group-item class="d-flex justify-content-between ">
                      <b-row class="d-flex align-items-center ml-1">
                        <b-col>
                          <b>{{ $t('statusSub') }}</b>
                        </b-col>
                        <b-col>
                          <b-form-group label="">
                            <b-form-select
                              id="isActive"
                              v-model="isActive"
                              :label="$t('message.tableHeader.status')"
                            >
                              <b-form-select-option
                                v-for="s in status"
                                :key="s.value"
                                :value="s.value"
                              >
                                {{ s.text }}
                              </b-form-select-option>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <div
                        v-if="categories.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgActive()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between ">
                      <b-row class="d-flex align-items-center ml-1">
                        <b-col>
                          <b>{{ $t('qualityCat') }}: </b>
                        </b-col>
                        <b-col>
                          <h5>
                            {{ qualityOptions[quality] }}
                          </h5>
                        </b-col>
                      </b-row>
                      <div
                        v-if="categories.length > 0"
                        class="d-flex align-self-center m-2"
                      >
                        <b-button
                          variant="success"
                          @click="asgQualityCategory()"
                        >
                          {{ $t('Asignar') }}
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item>
                      <b-row>
                        <b-col>
                          <b-form-group
                            class="mb-2 mr-1"
                          >
                            <label class="d-flex">
                              {{ $t('tagsCat') }}
                              <feather-icon
                                v-b-tooltip.hover.top.v-primary="$t('addTags')
                                "
                                class="ml-1"
                                icon="InfoIcon"
                              />
                            </label>
                            <v-select
                              v-model="selectedTag"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              multiple
                              taggable
                              push-tags
                              :placeholder="$t('addTagsC')"
                              @input="modalTags()"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="categories.length > 0"
                          class="align-self-center m-2"
                          style="text-align: end;"
                        >
                          <b-button
                            variant="success"
                            :disabled="selectedTag==0"
                            @click="asgTags(true)"
                          >
                            {{ $t('Asignar') }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>

        <b-card v-if="external != null ? external.length != 0 : false">
          <b-card-header>
            <b-card-title>
              {{ $t('problem') }}
              {{ dataExternal.length }}
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row class="d-flex  mr-1">
              <b-col class="ml-2 mb-2">
                <b-table
                  id="dataExternal"
                  striped
                  responsive
                  :sticky-header="true"
                  :no-border-collapse="true"
                  :items="dataExternal"
                  :fields="tableColumns"
                  class="mb-0"
                >
                  <template #cell(Contenido)="data">
                    <div class="d-flex align-items-center">
                      <b-img
                        :src="buildImageUrl(data.item.imageUrl)"
                        style=" margin-right: 2rem;
                            width: 6rem;"
                        @error="errorImg"
                      />
                      <b class="m-2">{{ data.item.name }}</b>
                    </div>
                  </template>
                  <template #cell(Editar)="data">
                    <div>
                      <router-link
                        :to="{
                          name: 'contents-edit',
                          params: { id: data.item.id }
                        }"
                      >
                        <b-button variant="info">
                          {{ $t('dataGeneric.edit') }}
                        </b-button>
                      </router-link>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormCheckbox,
  BFormSelectOption,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BListGroup, VBTooltip,
  BBadge,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BImg,
  BTable,
  BAlert,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  utf8ToB64, showToast, messageError, specialCategory,
} from '@/store/functions'

import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import configCategoryType from '@core/utils/categoryType'
import * as constants from '@core/utils/constants'
import { getQualityOptions } from '@/@core/utils/utils'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'

const noCover = require('@/assets/images/backend/nocover.jpeg')
const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BFormSelect,
    BFormGroup,
    BBadge,
    BFormCheckbox,
    BFormInput,
    flatPickr,
    BButton,
    BRow,
    BCol,
    BListGroupItem,
    BAlert,
    BCard,
    BFormSelectOption,
    BCardHeader,
    vSelect,
    BListGroup,
    BCardTitle,
    BCardBody,
    BTable,
    BImg,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    code: {
      type: String,
      default: '',
    },
    response: {
      type: Object,
      default() {
        return {}
      },
    },
    final: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      specialCategory,
      show: false,
      isDownload: false,
      showContentsCategories: false,
      hasSubtitle: false,
      headers: {},
      isAds: false,
      showKeep: false,
      contents: [],
      buscado: false,
      showAds: false,
      showDownload: false,
      maincategory: null,
      seasonCategory: null,
      quality: 'No_Quality',
      isFreemium: false,
      qualityOptions: getQualityOptions(),
      buscadoCat: false,
      isActive: 'RED',
      sponsor: false,
      userData: getUserData(),
      categoriesActives: [
        { value: true, text: this.$t('activar') },
        { value: false, text: this.$t('slider.deactivate') },
      ],
      background: null,
      backgroundUrl: null,
      shortDescription: null,
      labelStatus: 'RED',
      status: [
        { value: 'RED', text: `🔴 ${this.$t('code.desactivado')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      genre: null,
      categories: [],
      categoryTypes: configCategoryType(true, false, false, false),
      type: null,
      clasification: null,
      external: [],
      selectedTag: [],
      idTags: [],
      originalTags: [],
      publishingDate: null,
      finishingDate: null,
      dataExternal: [],
      tableColumns: [
        { key: 'Contenido', label: this.$t('contents.content') },
        { key: 'Editar', label: this.$t('dataGeneric.edit') },
      ],
      columns: [
        { key: 'Contenido', label: this.$t('contents.content') },
        { key: 'prev', label: this.$t('prevCha') },
        { key: 'next', label: this.$t('nextChap') },
      ],
      chapters: [],
      platformMode: '',
      buscadoKeep: false,
      maxViews: 0,
    }
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    if (this.userData.groups.edges.length !== 0) {
      this.sponsor = this.userData.groups.edges[0].node.name === constants.sponsor
    }
    this.getClientData()
  },
  methods: {
    getClientData() {
      axios
        .post('', {
          query: `
             query{
             client(id:"${this.userData.profile.client.id}"){
                 platformMode
                 isAds
                 isDownload
             }
         }
         `,
        })
        .then(res => {
          this.platformMode = res.data.data.client.platformMode
          this.showAds = res.data.data.client.isAds
          this.showDownload = res.data.data.client.isDownload
        })
        .catch(() => {
        })
    },
    okAsg() {
      const { headers } = this
      this.showKeep = true
      let query = 'mutation{'

      this.chapters.forEach((element, index) => {
        query += `q${index + 1}:
                updateContents(id:"${element.id}",input:{previousChapter:"${index === 0 ? '' : this.chapters[index - 1].id
}",nextChapter:"${index === this.chapters.length - 1 ? '' : this.chapters[index + 1].id
}"}){
                  content {
                    id
                    name
                  }
                }
              `
      })

      query += '}'
      axios
        .post('', {
          query,
        }, { headers })
        .then(res => {
          messageError(res, this).then(result => {
            if (!result) {
              showToast(
                this.$t('success'),
                1, this,
              )
            }
          }).catch(() => {

          })

          this.showKeep = false
        })
        .catch(error => {
          this.showKeep = false
          console.log(error)

          showToast(this.$t('error'), 2, this)
        })
    },
    asgKeepWatching() {
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if2'),
        showCancelButton: true,
        confirmButtonText: this.$t('next'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            // this.$refs["my-modal"].show();
            this.okAsg()
          } else {
            this.showKeep = false
          }
        })
        .catch(() => { })
    },
    async keepWatching() {
      this.getInfo()

      this.showKeep = true
      this.buscadoKeep = true
      this.buscadoCat = false
      this.buscado = false

      this.contents = []
      this.external = []
      this.dataExternal = []
      this.chapters = []
      await this.getRelations(this.code)
    },
    asgImg() {
      const { headers } = this
      if (this.contents.length > 0) {
        this.$swal({
          title: this.$t('warning'),
          text: this.$t('automation.if3'),
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: this.$t('dataGeneric.change'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.show = true
              const config = {
                timeout: 1000 * 60 * 30,
              }
              const data = new FormData()
              let query = 'mutation {'
              for (let i = 0; i < this.contents.length; i += 1) {
                query += `
              m${i + 1}: updateContents(id: "${this.contents[i]}", input:
                {
                  mediaLocation: BUK
                }){
                content{
                  id
                  name

                }
              }
          `
              }

              query += '}'
              data.append('query', query)
              data.append('imageBack', this.background)

              axios
                .post('', data, { headers }, config)
                .then(res => {
                  messageError(res, this).then(r => {
                    if (!r) {
                      showToast(
                        this.$t('success'),
                        1, this,
                      )
                    }
                  }).catch(() => {

                  })

                  if (this.external.length > 0) {
                    this.$swal({
                      title: this.$t('IntroUrl'),
                      text: this.$t('automation.if4'),
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: this.$t('confirm'),
                      cancelButtonText: this.$t('dataGeneric.cancel'),
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                      },

                      buttonsStyling: false,
                    })
                      .then(r => {
                        if (r.value) {
                          this.show = true
                          const configr = {
                            timeout: 1000 * 60 * 30,
                          }
                          const dataR = new FormData()
                          let q = 'mutation {'
                          for (let i = 0; i < this.external.length; i += 1) {
                            q += `
                            m${i + 1}: updateContents(id: "${this.external[i]}", input:
                              {
                                mediaLocation: DIR,
                                backgroundUrl: "${this.backgroundUrl}"
                              }){
                              content{
                                id
                                name

                              }
                            }
                        `
                          }

                          q += '}'
                          dataR.append('query', q)

                          axios
                            .post('', dataR, { headers }, configr)
                            .then(response => {
                              messageError(response, this).then(resMess => {
                                if (!resMess) {
                                  showToast(
                                    this.$t('success'),
                                    1, this,
                                  )
                                }
                              }).catch(() => {

                              })
                              this.show = false
                            })
                            .catch(error => {
                              this.show = false
                              console.log(error)

                              showToast(
                                this.$t('error'),
                                2, this,
                              )
                            })
                        }
                      })
                      .catch(() => { })
                  }
                  this.show = false
                })
                .catch(error => {
                  this.show = false
                  console.log(error)

                  showToast(this.$t('error'), 2, this)
                })
            }
          })
          .catch(() => { })
      } else if (this.external.length > 0) {
        this.$swal({
          title: this.$t('IntroUrl'),
          text: this.$t('automation.if4'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },

          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.show = true
              const config = {
                timeout: 1000 * 60 * 30,
              }
              const data = new FormData()
              /*  */
              let query = 'mutation {'
              for (let i = 0; i < this.external.length; i += 1) {
                query += `
              m${i + 1}: updateContents(id: "${this.external[i]}", input:
                {
                  mediaLocation: DIR,
                  backgroundUrl: "${this.backgroundUrl}"
                }){
                content{
                  id
                  name

                }
              }
          `
              }

              query += '}'
              data.append('query', query)

              axios
                .post('', data, { headers }, config)
                .then(res => {
                  messageError(res, this).then(r => {
                    if (!r) {
                      showToast(
                        this.$t('success'),
                        1, this,
                      )
                    }
                  }).catch(() => {

                  })
                  this.show = false
                })
                .catch(error => {
                  this.show = false
                  console.log(error)

                  showToast(this.$t('error'), 2, this)
                })
            }
          })
          .catch(() => { })
      }
    },
    asgClas() {
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if5'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  clasification: "${this.clasification.id}"
                }){
                content{
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)
            const { headers } = this

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    asgGen() {
      const { headers } = this
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if1'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  genre: "${this.genre.id}"
                }){
                content{
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    asgMaxViews() {
      const { headers } = this
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if6'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  maxViews: ${this.maxViews}
                }){
                content{
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    asgShortDes() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if7'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  shortDescription: "${this.shortDescription}"
                }){
                content{
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    asgDownload() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if8'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  isDownload: ${this.isDownload}
                }){
                content{
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      `Opción añadida con éxito a ${totalArray.length} elementos`,
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    asgCheckContents() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if19'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  showContentsCategories: ${this.showContentsCategories}
                }){
                content{
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      `Opción añadida con éxito a ${totalArray.length} elementos`,
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    asgFreemium() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if18'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  isFreemium: ${this.isFreemium}
                }){
                content{
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      `Opción añadida con éxito a ${totalArray.length} elementos`,
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    asgSub() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if17'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  hasSubtitle: ${this.hasSubtitle}
                }){
                content{
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    asgAds() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if9'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  isAds: ${this.isAds}
                }){
                content{
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    modalTags() {
      if (this.selectedTag.length > this.idTags.length) {
        let id = ''
        axios
          .post('', {
            query: `
          {
            allTags(name:"${this.selectedTag[this.selectedTag.length - 1]}") {
              edges {
                node {
                  id
                  name
                  createdAt

                }
              }
            }
          }
        `,
          })
          .then(result => {
            messageError(result, this)

            let crear = true
            result.data.data.allTags.edges.forEach(element => {
              if (
                element.node.name.toLowerCase()
                === this.selectedTag[this.selectedTag.length - 1].toLowerCase()
              ) {
                id = element.node.id
                crear = false
              }
            })

            if (crear) {
              /* AQUÍ CREA EL TAG */

              axios
                .post('', {
                  query: `
              mutation{
                createTag(input:{name:"${this.selectedTag[this.selectedTag.length - 1]}"}){
                    tag{
                        id
                        name
                    }
                }
            }
            `,
                })
                .then(res => {
                  messageError(res, this)

                  this.idTags.push(res.data.data.createTag.tag.id)
                })
                .catch(() => { })
            } else {
              this.idTags.push(id)
            }
          })
          .catch(() => { })
      } else {
        this.originalTags.forEach((a, index) => {
          let quitar = true
          this.selectedTag.forEach(element => {
            if (element.toLowerCase() === a.toLowerCase()) {
              quitar = false
            }
          })
          if (quitar) {
            this.idTags.splice(index, 1)
            this.originalTags.splice(index, 1)
          }
        })
      }
    },
    asgMainCategory() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if10', { maincategory: this.maincategory }),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  mainCategory: "${this.response.id}"
                }){
                content{
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    asgSeasonCategory() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if11', { seasonCategory: this.seasonCategory }),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  seasonCategory: "${this.response.id}"
                }){
                content{
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    asgTags(categories = false) {
      let text = ''
      if (categories) {
        text = this.$t('automation.if21', { tags: this.selectedTag.join(', ') })
      } else {
        text = this.$t('automation.if20', { tags: this.selectedTag.join(', ') })
      }

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text,
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = !categories ? [...this.contents, ...this.external] : [...this.categories]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: ${categories ? 'updateCategory' : 'updateContents'}(id: "${totalArray[i]}", input:
                {
                  tags: [${this.idTags}]
                }){
                  ${categories ? 'category' : 'content'}
                {
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)
            const { headers } = this

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    asgQuality() {
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if15', { quality: this.qualityOptions[this.quality] }),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation($quality: ContentQualityInput) {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  quality: $quality
                }){
                content{
                  id
                  name

                }
              }
          `
            }
            query += '}'
            const variables = {
              quality: this.quality !== 'No_Quality' ? this.quality : null,
            }
            data.append('variables', JSON.stringify(variables))
            data.append('query', query)
            const { headers } = this

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },

    asgType() {
      const { headers } = this

      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if12'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  type: ${this.type}
                }){
                content{
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    getInfo() {
      this.background = this.response.background
      this.backgroundUrl = this.response.backgroundUrl
      this.genre = this.response.genre
      this.type = this.response.type
      this.clasification = this.response.clasification
      this.shortDescription = this.response.shortDescription
      this.maincategory = this.response.name
      this.seasonCategory = this.response.name
      this.quality = this.response.quality !== null ? this.response.quality : 'No_Quality'
      this.response.tags.edges.forEach(element => {
        this.selectedTag.push(element.node.name)
        this.idTags.push(element.node.id)
        this.originalTags.push(element.node.name)
      })
    },
    async clickElement() {
      this.getInfo()

      this.show = true
      this.buscado = true
      this.buscadoCat = false
      this.buscadoKeep = false

      this.contents = []
      this.external = []
      this.dataExternal = []
      this.chapters = []

      await this.getData(this.code)

      this.show = false
    },
    exitFunct() {
      if (this.external.length > 0) {
        this.$swal({
          title: this.$t('exter'),
          text: this.$t('existExter', { length: this.external.length }),
          icon: 'info',
          confirmButtonText: this.$t('code.accept'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
          .then(() => {

          })
          .catch(() => { })
      }
      this.show = false
    },
    async getRelations(id) {
      const { headers } = this

      const totalIdsCategories = []
      totalIdsCategories.push(id)

      for (let i = 0; i < totalIdsCategories.length; i += 1) {
        const encondedCode = utf8ToB64(
          `category:${totalIdsCategories[i]}`,
        )
        // eslint-disable-next-line no-await-in-loop
        await axios
          .post('', {
            query: `
            query{
                allCategories(id:"${totalIdsCategories[i]}") {
                    edges {
                    node {
                        id
                        name
                        isFinal
                        categoryContent(orderBy:"order,-created_at") {
                          totalCount
                            edges {
                                node {
                                    id
                                    type
                                    mediaLocation
                                    name
                                    imageUrl
                                    order
                                  contentContentOrder(category:"${encondedCode}") {
                                    totalCount
                                    edges {
                                      node {
                                        order
                                      }
                                    }
                                  }
                                }
                            }
                        }
                        childCategories ${this.sponsor ? `(filter_Sponsor:"${this.userData.id}")` : ''}  {
                        edges {
                            node {
                            id
                            isFinal
                            order
                            childCategories ${this.sponsor ? `(filter_Sponsor:"${this.userData.id}")` : ''}  {
                                edges {
                                node {
                                    id
                                    isFinal
                                    order
                                }
                                }
                            }
                            categoryContent(orderBy:"order,-created_at"${this.sponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}) {
                              totalCount
                                edges {
                                node {
                                    id
                                    type
                                    mediaLocation
                                    name
                                    imageUrl
                                    order
                                  contentContentOrder(category:"${encondedCode}") {
                                    totalCount
                                    edges {
                                      node {
                                        order
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
                `,
          }, { headers })
          .then(result => {
            messageError(result, this)

            if (result.data.data.allCategories.edges[0].node.isFinal) {
              let conts = result.data.data.allCategories.edges[0].node.categoryContent
                .edges
              conts = conts.sort(
                (a, b) => (a.node.contentContentOrder.totalCount === 0
                  ? a.node.order
                  : a.node.contentContentOrder.edges[0].node.order)
                  - (b.node.contentContentOrder.totalCount === 0
                    ? b.node.order
                    : b.node.contentContentOrder.edges[0].node.order),
              )

              conts.forEach((element, j) => {
                const naPrev = j === 0 ? '' : conts[j - 1].node.name
                const naNext = j === conts.length - 1 ? '' : conts[j + 1].node.name
                const imagePrev = j === 0 ? '' : conts[j - 1].node.imageUrl
                const imageNext = j === conts.length - 1 ? '' : conts[j + 1].node.imageUrl
                //  Unir capitulos de distintas categorías
                let season = true
                try {
                  if (j === 0) {
                    if (this.chapters.length !== 0) {
                      season = false
                      this.chapters[this.chapters.length - 1].next = {
                        name: conts[j].node.name,
                        imageUrl: conts[j].node.imageUrl,
                      }
                      // eslint-disable-next-line no-param-reassign
                      element.node.prev = {
                        name: this.chapters[this.chapters.length - 1].name,
                        imageUrl: this.chapters[this.chapters.length - 1]
                          .imageUrl,
                      }

                      // eslint-disable-next-line no-param-reassign
                      element.node.next = { name: naNext, imageUrl: imageNext }
                    }
                  }
                } catch (error) {
                  console.log(error)
                }

                if (season) {
                  // eslint-disable-next-line no-param-reassign
                  element.node.prev = { name: naPrev, imageUrl: imagePrev }
                  // eslint-disable-next-line no-param-reassign
                  element.node.next = { name: naNext, imageUrl: imageNext }
                }

                this.chapters.push(element.node)
              })
            } else {
              let childCat = result.data.data.allCategories.edges[0].node.childCategories
                .edges
              childCat = childCat.sort((a, b) => a.node.order - b.node.order)

              childCat.forEach(element => {
                totalIdsCategories.push(element.node.id)
              })
            }
          })
          .catch(() => { })
      }
      this.showKeep = false
    },
    /*  async getRelations(id) {
      const { headers } = this

      const totalIdsCategories = [id]

      // Función para construir la consulta GraphQL
      const buildQuery = categoryId => `
    query {
      allCategories(id: "${categoryId}") {
        edges {
          node {
            id
            name
            isFinal
            categoryContent(orderBy: "order,-created_at") {
              totalCount
              edges {
                node {
                  id
                  type
                  mediaLocation
                  name
                  imageUrl
                  order
                  contentContentOrder(category: "${utf8ToB64(`category:${categoryId}`)}") {
                    totalCount
                    edges {
                      node {
                        order
                      }
                    }
                  }
                }
              }
            }
            childCategories ${this.sponsor ? `(filter_Sponsor: "${this.userData.id}")` : ''} {
              edges {
                node {
                  id
                  isFinal
                  order
                  childCategories ${this.sponsor ? `(filter_Sponsor: "${this.userData.id}")` : ''} {
                    edges {
                      node {
                        id
                        isFinal
                        order
                      }
                    }
                  }
                  categoryContent(orderBy: "order,-created_at" ${this.sponsor ? `,creator: "${utf8ToB64(`id:${this.userData.id}`)}"` : ''}) {
                    totalCount
                    edges {
                      node {
                        id
                        type
                        mediaLocation
                        name
                        imageUrl
                        order
                        contentContentOrder(category: "${utf8ToB64(`category:${categoryId}`)}") {
                          totalCount
                          edges {
                            node {
                              order
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `

      try {
        const promises = totalIdsCategories.map(categoryId => axios.post('', { query: buildQuery(categoryId) }, { headers }))

        const results = await Promise.all(promises)

        results.forEach(result => {
          messageError(result, this)

          const { node } = result.data.data.allCategories.edges[0]

          if (node.isFinal) {
            let conts = node.categoryContent.edges
            conts = conts.sort(
              (a, b) => (a.node.contentContentOrder.totalCount === 0
                ? a.node.order
                : a.node.contentContentOrder.edges[0].node.order)
                - (b.node.contentContentOrder.totalCount === 0
                  ? b.node.order
                  : b.node.contentContentOrder.edges[0].node.order),
            )

            conts.forEach((element, i) => {
              const naPrev = i === 0 ? '' : conts[i - 1].node.name
              const naNext = i === conts.length - 1 ? '' : conts[i + 1].node.name
              const imagePrev = i === 0 ? '' : conts[i - 1].node.imageUrl
              const imageNext = i === conts.length - 1 ? '' : conts[i + 1].node.imageUrl
              let season = true

              try {
                if (i === 0 && this.chapters.length !== 0) {
                  season = false
                  this.chapters[this.chapters.length - 1].next = {
                    name: conts[i].node.name,
                    imageUrl: conts[i].node.imageUrl,
                  }
                  // eslint-disable-next-line no-param-reassign
                  element.node.prev = {
                    name: this.chapters[this.chapters.length - 1].name,
                    imageUrl: this.chapters[this.chapters.length - 1].imageUrl,
                  }
                  // eslint-disable-next-line no-param-reassign
                  element.node.next = { name: naNext, imageUrl: imageNext }
                }
              } catch (error) {
                console.log(error)
              }

              if (season) {
                // eslint-disable-next-line no-param-reassign
                element.node.prev = { name: naPrev, imageUrl: imagePrev }
                // eslint-disable-next-line no-param-reassign
                element.node.next = { name: naNext, imageUrl: imageNext }
              }

              this.chapters.push(element.node)
            })
          } else {
            let childCat = node.childCategories.edges
            childCat = childCat.sort((a, b) => a.node.order - b.node.order)
            childCat.forEach(element => {
              totalIdsCategories.push(element.node.id)
            })
          }
        })
      } catch (error) {
        console.error(error)
      }

      this.showKeep = false
    }, */
    async getData(id) {
      const { headers } = this

      const totalIdsCategories = []
      totalIdsCategories.push(id)
      for (let i = 0; i < totalIdsCategories.length; i += 1) {
        const encondedCode = utf8ToB64(
          `category:${totalIdsCategories[i]}`,
        )
        // eslint-disable-next-line no-await-in-loop
        await axios
          .post('', {
            query: `
        query{
        allCategories(id:"${totalIdsCategories[i]}") {
            edges {
            node {
                id
                name
                isFinal
                categoryContent(orderBy:"order,-created_at") {
                  totalCount
                    edges {
                        node {
                            id
                            type
                            mediaLocation
                            name
                            imageUrl
                            order
                          contentContentOrder(category:"${encondedCode}") {
                            totalCount
                            edges {
                              node {
                                order
                              }
                            }
                          }
                        }
                    }
                }
                childCategories${this.sponsor ? `(filter_Sponsor:"${this.userData.id}")` : ''}  {
                edges {
                    node {
                    id
                    isFinal
                    order
                    childCategories${this.sponsor ? `(filter_Sponsor:"${this.userData.id}")` : ''}  {
                        edges {
                        node {
                            id
                            isFinal
                            order
                        }
                        }
                    }
                    categoryContent(orderBy:"order,-created_at"${this.sponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}) {
                      totalCount
                        edges {
                        node {
                            id
                            type
                            mediaLocation
                            name
                            imageUrl
                            order
                          contentContentOrder(category:"${encondedCode}") {
                            totalCount
                            edges {
                              node {
                                order
                              }
                            }
                          }
                        }
                        }
                    }
                    }
                }
                }
            }
            }
        }
        }
        `,
          }, { headers })
          .then(result => {
            messageError(result, this)
            //  TODO Tener en cuenta la cantidad de contenidos
            if (result.data.data.allCategories.edges[0].node.isFinal) {
              let conts = result.data.data.allCategories.edges[0].node.categoryContent
                .edges
              conts = conts.sort(
                (a, b) => (a.node.contentContentOrder.totalCount === 0
                  ? a.node.order
                  : a.node.contentContentOrder.edges[0].node.order)
                  - (b.node.contentContentOrder.totalCount === 0
                    ? b.node.order
                    : b.node.contentContentOrder.edges[0].node.order),
              )

              conts.forEach(element => {
                if (element.node.mediaLocation === 'BUK') {
                  this.contents.push(element.node.id)
                } else {
                  this.external.push(element.node.id)
                  this.dataExternal.push(element.node)
                }
              })
            } else {
              let childCat = result.data.data.allCategories.edges[0].node.childCategories
                .edges
              childCat = childCat.sort((a, b) => a.node.order - b.node.order)

              childCat.forEach(element => {
                totalIdsCategories.push(element.node.id)
              })
            }
          })
          .catch(() => { })
      }

      this.exitFunct()
    },
    /* async getData(id) {
      console.log('getData')
      const { headers } = this
      const totalIdsCategories = [id]

      // Función para construir la consulta GraphQL
      const buildQuery = categoryId => `
    query {
      allCategories(id: "${categoryId}") {
        edges {
          node {
            id
            name
            isFinal
            categoryContent(orderBy: "order,-created_at") {
              totalCount
              edges {
                node {
                  id
                  type
                  mediaLocation
                  name
                  imageUrl
                  order
                  contentContentOrder(category: "${utf8ToB64(`category:${categoryId}`)}") {
                    totalCount
                    edges {
                      node {
                        order
                      }
                    }
                  }
                }
              }
            }
            childCategories${this.sponsor ? `(filter_Sponsor: "${this.userData.id}")` : ''} {
              edges {
                node {
                  id
                  isFinal
                  order
                  childCategories${this.sponsor ? `(filter_Sponsor: "${this.userData.id}")` : ''} {
                    edges {
                      node {
                        id
                        isFinal
                        order
                      }
                    }
                  }
                  categoryContent(orderBy: "order,-created_at" ${this.sponsor ? `,creator: "${utf8ToB64(`id:${this.userData.id}`)}"` : ''}) {
                    totalCount
                    edges {
                      node {
                        id
                        type
                        mediaLocation
                        name
                        imageUrl
                        order
                        contentContentOrder(category: "${utf8ToB64(`category:${categoryId}`)}") {
                          totalCount
                          edges {
                            node {
                              order
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `

      try {
        const promises = totalIdsCategories.map(categoryId => axios.post('', { query: buildQuery(categoryId) }, { headers }))

        const results = await Promise.all(promises)

        results.forEach(result => {
          messageError(result, this)

          const { node } = result.data.data.allCategories.edges[0]

          if (node.isFinal) {
            let conts = node.categoryContent.edges
            conts = conts.sort(
              (a, b) => (a.node.contentContentOrder.totalCount === 0
                ? a.node.order
                : a.node.contentContentOrder.edges[0].node.order)
                - (b.node.contentContentOrder.totalCount === 0
                  ? b.node.order
                  : b.node.contentContentOrder.edges[0].node.order),
            )

            conts.forEach(element => {
              if (element.node.mediaLocation === 'BUK') {
                this.contents.push(element.node.id)
              } else {
                this.external.push(element.node.id)
                this.dataExternal.push(element.node)
              }
            })
          } else {
            let childCat = node.childCategories.edges
            childCat = childCat.sort((a, b) => a.node.order - b.node.order)

            childCat.forEach(element => {
              totalIdsCategories.push(element.node.id)
            })
          }
        })
      } catch (error) {
        console.error(error)
      }

      this.exitFunct()
    }, */

    buildImageUrl(folder) {
      return folder == null || folder.length === 0
        ? noCover
        : folder
    },
    errorImg(e) {
      e.target.src = fileError
    },
    clickCategory() {
      this.getInfo()

      this.show = true
      this.buscadoCat = true
      this.buscado = false
      this.external = []

      this.categories = []

      this.getDataCategory(this.code)

      this.show = false
    },
    asgActive() {
      const { headers } = this
      const isActiveMap = {
        GRE: this.$t('ecommerce.public'),
        RED: this.$t('code.desactivado'),
        default: this.$t('dataGeneric.internal'),
      }
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if13', { isActive: isActiveMap[this.isActive] || isActiveMap.default }),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let active = false
            switch (this.isActive) {
              case 'RED':
                active = false
                break
              default:
                active = true
                break
            }
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation {'
            for (let i = 0; i < this.categories.length; i += 1) {
              query += `
              m${i + 1}: updateCategory(id: "${this.categories[i]}", input:
                {
                  state: ${this.isActive},
                  isActive: ${active},
                }){
                category{
                  id
                  name

                }
              }
          `
            }

            query += '}'
            data.append('query', query)

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    asgQualityCategory() {
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if16', { quality: this.qualityOptions[this.quality] }),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            let query = 'mutation($quality: CategoryQualityInput) {'
            for (let i = 0; i < this.categories.length; i += 1) {
              query += `
              m${i + 1}: updateCategory(id: "${this.categories[i]}", input:
                {
                  quality: $quality
                }){
                category{
                  id
                  name

                }
              }
          `
            }
            query += '}'

            const variables = {
              quality: this.quality !== 'No_Quality' ? this.quality : null,
            }
            data.append('variables', JSON.stringify(variables))
            data.append('query', query)
            const { headers } = this

            axios
              .post('', data, { headers }, config)
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log(error)

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },

    getDataCategory(id) {
      const { headers } = this

      axios
        .post('', {
          query: `
    query{
        allCategories(id:"${id}") {
            edges {
            node {
                id
                categoryContent(orderBy:"order,-created_at"${this.sponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}) {
                    edges {
                        node {
                            id
                            mediaLocation
                            name
                            imageUrl
                        }
                    }
                }
                childCategories${this.sponsor ? `(filter_Sponsor:"${this.userData.id}")` : ''}  {
                edges {
                    node {
                    id
                    childCategories {
                        edges {
                        node {
                            id
                        }
                        }
                    }
                    categoryContent(orderBy:"order,-created_at"${this.sponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}) {
                        edges {
                        node {
                            id
                            mediaLocation
                             name
                            imageUrl
                        }
                        }
                    }
                    }
                }
                }
            }
            }
        }
        }
        `,
        }, { headers })
        .then(result => {
          messageError(result, this)

          if (
            result.data.data.allCategories.edges[0].node.categoryContent.edges
              .length === 0
          ) {
            result.data.data.allCategories.edges[0].node.childCategories.edges.forEach(
              element => {
                this.categories.push(element.node.id)
                this.getDataCategory(element.node.id)
              },
            )
          }
        })
        .catch(() => { })
    },

    asgStatus() {
      const { headers } = this

      const isActive = !!(this.labelStatus === 'GRE' || this.labelStatus === 'YEW')

      const dateIn = this.fechaFormato(this.publishingDate)
      const dateFin = this.fechaFormato(this.finishingDate)
      this.$swal({
        title: this.$t('warning'),
        icon: 'error',
        text: this.$t('automation.if14'),
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.change'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let totalArray = []

            totalArray = [...this.contents, ...this.external]
            this.show = true

            let query = this.labelStatus === 'GRE'
              ? 'mutation($publishDate: DateTime,$expirationDate: DateTime){'
              : 'mutation{'
            for (let i = 0; i < totalArray.length; i += 1) {
              query += `
              m${i + 1}: updateContents(id: "${totalArray[i]}", input:
                {
                  state: ${this.labelStatus},
                   isActive:${isActive},
              ${this.labelStatus === 'GRE'
    ? ` publishDate: $publishDate,
                  expirationDate: $expirationDate,`
    : ''
}
                }){
                content{
                  id
                  name

                }
              }
          `
            }

            query += '}'

            axios
              .post('', {
                variables: {
                  publishDate: dateIn,
                  expirationDate: dateFin,
                },
                query,
              }, { headers })
              .then(res => {
                messageError(res, this).then(r => {
                  if (!r) {
                    showToast(
                      this.$t('success'),
                      1, this,
                    )
                  }
                }).catch(() => {

                })

                this.show = false
              })
              .catch(error => {
                console.log(error)
                this.show = false

                showToast(this.$t('error'), 2, this)
              })
          }
        })
        .catch(() => { })
    },
    estados() {
      if (this.labelStatus === 'GRE') {
        document.getElementById('fechas').style.display = 'flex'
      } else {
        document.getElementById('fechas').style.display = 'none'
      }
    },
    fechaFormato(value) {
      const fecha = new Date(value)

      return value != null ? fecha : value
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#automation .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 500px;
}

#automation .vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator {
  cursor: not-allowed;
  background-color: transparent !important;
  opacity: 0.8 !important;
  /* background-color: #f8f8f8; */
}
</style>
